<template>
	<div class="app-container">		
		<div class="filter-container mt-3">
			<el-button class="filter-item float-right" type="primary" :loading="buttonloading" v-b-modal.addRow v-if="permissionList.includes(permission.add)" icon="el-icon-plus">{{$t('button.add')}}</el-button>
		</div>
		
		<el-table :data="tableData" v-loading="loading" style="width: 100%;" @expand-change="expandChange" @row-click="expandRow" class="mt-3" ref="tableTest" border fit highlight-current-row>
			<template v-for="title in ajaxTitles">
				<el-table-column v-if="title.prop == 'img_url'" :prop="title.prop" :label="title.label" :key="title.prop" :min-width="title.width">
					<template slot="header">
						<p class="search-label">{{title.label}}</p>
						<b-form-input :placeholder="title.label" @keyup.enter.native="initial" v-model="searchData[title.prop]" disabled></b-form-input>
					</template>
					
					<template slot-scope="scope" >
						<el-image :src="scope.row.img_url" class="img-thumbnail"></el-image>
					</template>
				</el-table-column>
				
				<el-table-column v-else :prop="title.prop" :label="title.label" :key="title.prop" :min-width="title.width">
					<template slot="header">
						<p class="search-label">{{title.label}}</p>
						<b-form-input :placeholder="title.label" @keyup.enter.native="initial" v-model="searchData[title.prop]" @change="saveData()"></b-form-input>
					</template>
				</el-table-column>
			</template>
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <el-button type="primary" :loading="buttonloading" @click="getEditRow(scope.row.id)" v-if="permissionList.includes(permission.edit)" icon="el-icon-edit">{{$t('button.edit')}}</el-button>
                    <el-button type="danger" :loading="buttonloading" @click="deleteRow(scope.row.id)" v-if="permissionList.includes(permission.delete)" icon="el-icon-delete">{{$t('button.delete')}}</el-button>
                </template>
            </el-table-column>
        </el-table>

		<pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="pagination"/>
		
		<el-tooltip placement="top" :content="$t('general.back_to_top')">
			<backtotop :visibility-height="300" :back-position="50" transition-name="fade" />
		</el-tooltip>
		
		<b-modal id="addRow" :title="$t('menu.catalog_brand_add')" @hide="clearDataList()" size="lg" hide-footer no-enforce-focus>
			<el-form id="addForm" @submit.prevent="submit" enctype="multipart/form-data">
				<b-row>
					<b-col cols="12" md="6">
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_code')}}</template>
							<b-form-input v-model="dataList.code"></b-form-input>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_sort')}}</template>
							<el-input-number v-model="dataList.sort" :min="0" class="w-100" :precision="0"></el-input-number>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_status')}}</template>
							<el-switch v-model="dataList.status" active-value="1" inactive-value="0"> </el-switch>
						</b-form-group>
					</b-col>
					
					<b-col cols="12" md="6">
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_security')}}</template>
							<b-form-input v-model="dataList.security" type="password" :placeholder="$t('msg.msg_security_password')"></b-form-input>
						</b-form-group>
						
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_image')}}</template>
							<el-popover placement="right" width="130" trigger="click" class="img-thumbnail" v-model="popover">
								<el-button type="primary" icon="el-icon-edit" @click="openImage();"></el-button>
								<el-button type="danger" icon="el-icon-delete" @click="initialImage();"></el-button>
								
								<el-image style="width: 100px; height: 100px" fit="fill" :src="dataList.img_url" slot="reference"></el-image>
							</el-popover>
						</b-form-group>
					</b-col>
					
					<b-col cols="12" md="12">
						<el-tabs class="mb-3">
							<el-tab-pane v-for="item in languageList" :key="item.id" :item="item" :label="item.name">
								<b-form-group label-cols="12" label-cols-lg="4">
									<template slot="label">{{$t('admin_general.table_name')}}</template>
									<b-form-input v-model="dataList.name[item.id]" :disabled="loading"></b-form-input>
								</b-form-group>
                                <b-form-group label-cols="12" label-cols-lg="4">
									<template slot="label">{{$t('admin_general.table_content')}}</template>
									<b-form-textarea v-model="dataList.content[item.id]" :disabled="loading" rows="4"></b-form-textarea>
								</b-form-group>
							</el-tab-pane>
						</el-tabs>
					</b-col>
				</b-row>
			</el-form>

			<el-footer align="center" class="element-footer">
				<el-button size="medium" @click="$bvModal.hide('addRow')">{{$t('button.cancel')}}</el-button>
				<el-button size="medium" type="primary" @click="addRow()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
			</el-footer>
        </b-modal>
		
		<b-modal id="editRow" :title="$t('menu.catalog_brand_edit')" @hide="clearDataList()" size="lg" hide-footer no-enforce-focus>
			<el-form id="editForm" @submit.prevent="submit" enctype="multipart/form-data">
				<b-row>
					<b-col cols="12" md="6">
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_code')}}</template>
							<b-form-input v-model="dataList.code"></b-form-input>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_sort')}}</template>
							<el-input-number v-model="dataList.sort" :min="0" class="w-100" :precision="0"></el-input-number>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_status')}}</template>
							<el-switch v-model="dataList.status" active-value="1" inactive-value="0"> </el-switch>
						</b-form-group>
					</b-col>
					
					<b-col cols="12" md="6">
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_security')}}</template>
							<b-form-input v-model="dataList.security" type="password" :placeholder="$t('msg.msg_security_password')"></b-form-input>
						</b-form-group>
						
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_image')}}</template>
							<el-popover placement="right" width="130" trigger="click" class="img-thumbnail" v-model="popover">
								<el-button type="primary" icon="el-icon-edit" @click="openImage();"></el-button>
								<el-button type="danger" icon="el-icon-delete" @click="initialImage();"></el-button>
								
								<el-image style="width: 100px; height: 100px" fit="fill" :src="dataList.img_url" slot="reference"></el-image>
							</el-popover>
						</b-form-group>
					</b-col>
					
					<b-col cols="12" md="12">
						<el-tabs class="mb-3">
							<el-tab-pane v-for="item in languageList" :key="item.id" :item="item" :label="item.name">
								<b-form-group label-cols="12" label-cols-lg="4">
									<template slot="label">{{$t('admin_general.table_name')}}</template>
									<b-form-input v-model="dataList.name[item.id]" :disabled="loading"></b-form-input>
								</b-form-group>
                                <b-form-group label-cols="12" label-cols-lg="4">
									<template slot="label">{{$t('admin_general.table_content')}}</template>
									<b-form-textarea v-model="dataList.content[item.id]" :disabled="loading" rows="4"></b-form-textarea>
								</b-form-group>
							</el-tab-pane>
						</el-tabs>
					</b-col>
				</b-row>
			</el-form>

			<el-footer align="center" class="element-footer">
				<el-button size="medium" @click="$bvModal.hide('editRow')">{{$t('button.cancel')}}</el-button>
				<el-button size="medium" type="primary" @click="editRow()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
			</el-footer>
        </b-modal>
		
		<imagemanager ref="imagemanager" :isShowDialog="openImageDialog" @dialogData="closeImageDialog" @getData="getImageDialog"/>
	</div>
</template>

<script>
import {getLocalStorage} from '@/system/store/localstorage';
import {postMethod} from '@/system/model/post/post';
import {getDAES, getEAES} from '@/system/library/security';
import pagination from '@/components/pagination';
import backtotop from '@/components/backtotop';
import imagemanager from '@/views/image';

let searchForm = {
	pagination: 1,
	limit: 10,
	id: '',
	code:'',
	date_range:'',
	created_at:'',
	sort:'',
	name:'',
	status:''
}

export default{
	components: { pagination, backtotop, imagemanager },
	inject:['preloader'],
	data(){
		return {
			loading: true,
			buttonloading: false,
			tableData: [],
			total: 0,
			errors: [],
			openImageDialog: false,
			submitForm: {
				id:'',
				security:''
			},
			postData: {
				data: '',
				language: ''
			},
			searchData: Object.assign({}, searchForm),
			listQuery: {
				page: 1,
				limit: 10
			},
			ajaxTitles:[{
                prop: "id",
                label: this.$t('admin_general.table_id'),
                width:'50'
            },{
                prop: "img_url",
                label: this.$t('admin_general.table_image'),
                width:'50'
			},{
                prop: "created_at",
                label: this.$t('admin_general.table_created_at'),
                width:'100'
			},{
                prop: "code",
                label: this.$t('admin_general.table_code'),
                width:'100'
			},{
                prop: "sort",
                label: this.$t('admin_general.table_sort'),
                width:'60'
			},{
                prop: "status",
                label: this.$t('admin_general.table_status'),
                width:'60'
			}],
			dataList:{
				id:1,
				code:'',
				status:'',
				sort:10,
				name:[],
				content:[],
				img_url: '',
				security:''
			},permission:{
                index:'H95JDK',
                ajaxTable:'AEE846',
                add:'HEQDHF',
                DBadd:'PLQHZ7',
                edit:'GDZSZY',
                DBedit:'ITGI5F',
                delete:'N146K3',
                DBdelete:'1NOQAG'
            },
			permissionList:[],
			languageList:[],
			pickerOptions: {
				shortcuts: [{
					text: this.$t('general.recent_week'),
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				},{
					text: this.$t('general.recent_one_month'),
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				},{
					text: this.$t('general.recent_three_month'),
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
            },
			popover: false,
			defaultImage: ''
		}
	}, methods: {
		getInitial(){
			if(this.permissionList.includes(this.permission.index)){
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.searchData));
				var self = this;
				var result = postMethod('company/certifica',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.defaultImage = data.defaultImg;
						self.initial();
						self.initialImage();
						self.buttonloading = false;
						self.loading = false;
					}
				});
			}
        },
		initial(){
			if(this.permissionList.includes(this.permission.ajaxTable)){
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.searchData));
				var self = this;
				var result = postMethod('company/certifica/ajaxTable',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.tableData = data.datatable.data;
						self.total = parseInt(data.datatable.total_number);
						self.listQuery.page = parseInt(data.datatable.current_pagination);
						self.listQuery.limit = parseInt(data.datatable.limit);
						self.buttonloading = false;
						self.loading = false;
					}
				});
			}
		},clearDataList(){
			this.dataList.code = '';
			this.dataList.sort = 10;
			this.dataList.status = 0;
			this.dataList.security = '';
			this.dataList.name = [];
            this.dataList.content = [];
            this.dataList.img_url = '';
		},addRow(){
			if(this.permissionList.includes(this.permission.DBadd)){
			
				this.preloader(true);
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.dataList));
				var text = '';
				var self = this;
				var result = postMethod('company/certifica/DBadd',this.postData);

				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.$message({
							message: data.returnMsg,
							type: 'success'
						});
						
						self.$bvModal.hide('addRow');
						self.initial();
					}else{					
						data.returnMsg.forEach(function (value){
							text+= value+"<br/>";
						});
						
						self.$method.popupErrorMessage(self,text);
					}
					
					self.buttonloading = false;
					self.preloader(false);
				});
			}
		},getEditRow(id){
			if(this.permissionList.includes(this.permission.edit)){
				this.buttonloading = true;
				this.submitForm.id = id;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var self = this;
				var result = postMethod('company/certifica/edit',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.dataList = data.thisDetail;
						self.$bvModal.show('editRow');
					}
					self.buttonloading = false;
				});
			}
		},editRow(){
			if(this.permissionList.includes(this.permission.DBedit)){
				this.buttonloading = true;
				this.preloader(true);
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.dataList));
				var text = '';
				var self = this;
				var result = postMethod('company/certifica/DBedit',this.postData);

				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.$message({
							message: data.returnMsg,
							type: 'success'
						});
						
						self.$bvModal.hide('editRow');
						self.initial();
					}else{					
						data.returnMsg.forEach(function (value){
							text+= value+"<br/>";
						});
						
						self.$method.popupErrorMessage(self,text);
					}
					
					self.buttonloading = false;
					self.preloader(false);
				});
			}
		},deleteRow(id){
			if(this.permissionList.includes(this.permission.DBdelete)){
				this.buttonloading = true;
				var text = '';
				var self = this;
				
				this.$prompt(this.$t('msg.msg_delete'), this.$t('msg.prompt'), {
					confirmButtonText: this.$t('button.confirm'),
					cancelButtonText: this.$t('button.cancel'),
					inputPlaceholder: this.$t('msg.msg_security_password'),
					inputType: 'password'
				}).then(({ value }) => {
					this.submitForm.id = id;
					this.submitForm.security = value;
					this.postData.data = '';
					this.postData.data = getEAES(JSON.stringify(this.submitForm));
					var result = postMethod('company/certifica/DBdelete',this.postData);
					result.then(function(value){
					var data = JSON.parse(getDAES(value.data));
					if(value.valid){
						self.$message({
						type: 'success',
						message: data.returnMsg
						});
						
						self.initial();
					}else{
						self.errors = data.returnMsg;
						
						self.errors.forEach(function (value){
						text+= value+"<br/>";
						});
						
						self.$method.popupErrorMessage(self, text);
					}
					
					});
					
					this.buttonloading = false;

				}).catch(() => {
					this.buttonloading = false;          
				});
			}
		},pagination(){
			this.searchData.pagination = this.listQuery.page;
			this.searchData.limit = this.listQuery.limit;
			this.initial();
		},expandRow(row){
            this.$refs.tableTest.toggleRowExpansion(row);
        },expandChange(row,expandedRows){
            if(expandedRows.length>1){
                this.$refs.tableTest.toggleRowExpansion(expandedRows[0]);
            }
        },initialImage(){
			this.dataList.img_url = this.defaultImage;
			this.popover = false;
		},closeImageDialog(data) {
			this.openImageDialog = data;
		},openImage(){
			this.openImageDialog = true;
			this.$refs.imagemanager.onOpen(0); 
			this.popover = false;
		},getImageDialog(data){
			this.dataList.img_url = data.path;
			this.openImageDialog = false;
		},saveData(){
			searchForm = this.searchData;
		}
	}, created: function(){
		var currentLang = (getLocalStorage('currentLang')) ? getLocalStorage('currentLang') : 'en';
		this.postData.language = currentLang;
		this.permissionList = JSON.parse(getLocalStorage('permissionList'));
		this.languageList = JSON.parse(getLocalStorage('languageList'));
		
		this.getInitial();
	}
}
</script>